* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  -webkit-overflow-scrolling: touch;
  color: #fff;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #212121;
  font-family: Switzer, Helvetica, Arial, Hiragino Kaku Gothic ProN, ヒラギノ角ゴ ProN W3, Hiragino Kaku Gothic Pro, ヒラギノ角ゴ Pro W3, Noto Sans JP, Meiryo, メイリオ, sans-serif;
}

html, body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

header {
  width: 100%;
  z-index: 2;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

header:before {
  content: " ";
  width: 100%;
  height: 100%;
  z-index: 0;
  background: #000;
  transition: transform .3s;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, -100%, 0);
}

header.active:before, header.active .cta-h {
  transform: translate3d(0, 0%, 0);
}

.cta-h {
  z-index: 1;
  column-gap: 10px;
  margin: 0 -15px 0 0;
  transition: transform .3s;
  display: flex;
  position: relative;
  transform: translate3d(0, -100%, 0);
}

.cta-hbtn {
  color: #fff;
  padding: 20px 15px;
  display: block;
  position: relative;
  overflow: hidden;
}

.cta-hbtn span {
  z-index: 1;
  position: relative;
}

@media (pointer: fine) {
  .cta-hbtn:before {
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #fff;
    transition: transform .3s;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 100%, 0);
  }

  .cta-hbtn span {
    transition: color .3s;
  }

  .cta-hbtn:hover {
    color: #212121;
  }

  .cta-hbtn:hover:before {
    transform: translate3d(0, 0, 0);
  }
}

.ppc {
  max-width: 700px;
}

.ppc h1, .ppc h3 {
  padding: 60px 0 10px;
}

.ppc a {
  color: #fff;
}

.h-logo {
  width: 100px;
  z-index: 1;
  font-size: 0;
  display: block;
  position: relative;
}

.h-logo img {
  width: 100%;
  height: auto;
}

p, h4, li, strong {
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  margin: 0;
}

h1, h2, h3, .specText {
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  margin: 0;
  font-family: PPEiko, Now, Helvetica, Arial, sans-serif;
  font-weight: 900;
}

h1 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 4vw;
  font-weight: 900;
  line-height: 4.2vw;
}

h2 {
  letter-spacing: .15em;
  text-transform: uppercase;
  padding: 30px 0;
  font-size: 60px;
  font-weight: 900;
  line-height: 76px;
}

h3 {
  padding: 0 0 6px;
  font-size: 30px;
  line-height: 38px;
}

a {
  text-decoration: none;
}

p {
  font-size: 16px;
  line-height: 28px;
}

::selection {
  background: #ff5c5c;
}

section {
  max-width: 1024px;
  margin: 0 auto;
  padding: 60px 0;
}

.main {
  width: 55%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 30px 60px;
  display: flex;
}

.kv {
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  overflow: hidden;
}

.kv:before, .kv:after {
  content: " ";
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.kv:before {
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: .02;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAAXNSR0IArs4c6QAAABdJREFUGFdjZGBg+M/AwMDIAAVwBvECAEDqAQXmMAK9AAAAAElFTkSuQmCC");
}

.kv:after {
  z-index: 2;
  border-top: 100vh solid #212121;
  border-bottom: 0 solid rgba(0, 0, 0, 0);
  border-left: 0 solid rgba(0, 0, 0, 0);
  border-right: 180px solid rgba(0, 0, 0, 0);
}

.kv video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.mainc {
  z-index: 1;
  position: relative;
}

@keyframes scroll {
  0% {
    transform: scaleX(0)translate3d(0%, -50%, 0);
  }

  50% {
    transform: scaleX(1)translate3d(0, -50%, 0);
  }

  100% {
    transform: scaleX(1)translate3d(100%, -50%, 0);
  }
}

.scroll {
  position: absolute;
  bottom: 0;
  left: 0;
}

.scroll span {
  transform-origin: top;
  padding: 0 40px 0 0;
  font-size: 14px;
  display: block;
  position: relative;
  overflow: hidden;
  transform: rotate(90deg)translate(-50%, 150%);
}

.scroll span:before {
  content: " ";
  width: 40px;
  height: 1px;
  transform-origin: 0;
  background: #fff;
  animation: scroll .6s linear 2s infinite;
  display: block;
  position: absolute;
  top: 50%;
  right: -10px;
}

.casestudy a {
  display: block;
}

.casestudy a p {
  color: #fff;
  text-transform: uppercase;
  padding: 5px 0 0;
  font-size: 14px;
}

.casestudy a img {
  width: 100%;
  height: auto;
}

@media (pointer: fine) {
  .casestudy a img {
    transition: opacity .3s;
  }

  .casestudy a:hover img {
    opacity: .8;
  }
}

.maindesc {
  max-width: 450px;
  padding: 25px 0 0;
}

.maindesc p {
  padding: 5px 0 0;
}

.cta {
  align-items: center;
  column-gap: 10px;
  padding: 25px 0 0;
  display: flex;
}

.cta-btn {
  color: #fff;
  text-align: center;
  border: 1px solid #ff5c5c;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
}

.cta-btn span {
  z-index: 1;
  position: relative;
}

@media (pointer: fine) {
  .cta-btn:before {
    content: " ";
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #ff5c5c;
    transition: transform .3s;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 100%, 0);
  }

  .cta-btn span {
    transition: color .3s;
  }

  .cta-btn:hover {
    color: #212121;
  }

  .cta-btn:hover:before {
    transform: translate3d(0, 0, 0);
  }
}

.tech-adv {
  padding: 120px 0 60px;
}

.psmall {
  text-align: center;
  font-size: 13px;
  line-height: 20px;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tadv-list video {
  max-width: 420px;
}

.tadv-list li {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  display: flex;
}

.tadv-list li div {
  width: 420px;
}

.tadv-list li.ar {
  justify-content: flex-end;
}

.mf-list {
  text-align: center;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin: 10px 0 48px;
  display: flex;
}

.mf-list li {
  width: 25%;
  text-transform: uppercase;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  font-size: 15px;
  display: flex;
}

.m-features h2 {
  text-align: center;
}

footer {
  padding: 200px 60px 15px;
}

.tfooter {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.bfooter {
  border-top: 1px solid #fff;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 0;
  display: flex;
}

.cpyright {
  font-size: 14px;
}

.cpyright a {
  color: #ccc;
  padding: 0 3px;
  display: inline-block;
}

.tftitle {
  opacity: .8;
  font-size: 12px;
  display: block;
}

.address .tftitle {
  display: none;
}

.address p {
  text-align: right;
  letter-spacing: .5px;
  padding: 3px 0 30px;
  font-size: 15px;
  line-height: 20px;
}

.contact {
  padding: 0 0 25px;
}

.contact a {
  color: #fff;
  letter-spacing: .5px;
  font-size: 32px;
  display: inline-block;
  position: relative;
}

@media (pointer: fine) {
  .contact a:before {
    content: " ";
    width: 100%;
    height: 1px;
    z-index: 0;
    background: #ff5c5c;
    transition: transform .3s;
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
    transform: scaleX(0)translateZ(0);
  }

  .contact a:hover:before {
    transform: scaleX(1)translateZ(0);
  }
}

.lang a {
  color: #fff;
  padding: 15px;
  font-size: 14px;
  display: block;
}

@media (pointer: fine) {
  .lang a {
    transition: opacity .3s;
  }

  .lang a:hover {
    opacity: .7;
  }
}

.sns {
  column-gap: 10px;
  margin: 0 -10px 0 0;
  display: flex;
}

.sns a {
  color: #fff;
  padding: 15px 10px;
  font-size: 14px;
  display: block;
}

@media (pointer: fine) {
  .sns a {
    transition: opacity .3s;
  }

  .sns a:hover {
    opacity: .7;
  }
}

@media (max-width: 1220px) {
  .main {
    width: 65%;
    padding: 30px;
  }

  .kv {
    width: 40%;
    left: 60%;
  }

  .kv:after {
    border-right: 120px solid rgba(0, 0, 0, 0);
  }

  h1 {
    font-size: 4.8vw;
    line-height: 5vw;
  }

  section {
    padding: 60px 30px;
  }

  .tech-adv {
    padding: 120px 30px 60px;
  }

  footer {
    padding: 200px 30px 15px;
  }
}

@media (max-width: 979px) {
  .tadv-list video {
    max-width: 380px;
  }

  .tadv-list li div {
    width: 380px;
  }
}

@media (max-width: 860px) {
  .scroll span {
    padding: 0 30px 0 0;
    font-size: 12px;
    transform: rotate(90deg)translate(-50%, 220%);
  }

  .scroll span:before {
    width: 30px;
    right: -10px;
  }

  header {
    padding: 0 30px;
  }

  .main {
    padding: 30px;
  }

  h2 {
    font-size: 40px;
    line-height: 45px;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .tadv-list video {
    max-width: none;
    width: 100%;
  }

  .tadv-list li {
    padding: 20px 0;
  }

  .tadv-list li div {
    width: 100%;
    max-width: 400px;
  }

  .awb div {
    margin: 0 0 0 auto;
    padding: 40px 0 0;
  }

  .lsm div, .bfp div {
    padding: 0 0 40px;
  }

  .bfp video {
    order: 1;
  }

  .pfg div {
    margin: 0 0 0 auto;
  }
}

@media (max-width: 767px) {
  h1, .maindesc {
    text-shadow: 0 0 15px rgba(0, 0, 0, .5);
  }

  h2 {
    padding: 20px 0;
    font-size: 30px;
    line-height: 35px;
  }

  h3 {
    font-size: 22px;
    line-height: 30px;
  }

  .mf-list li {
    width: 33.33%;
    padding: 15px 0;
    font-size: 13px;
  }

  .mf-list li:last-child {
    width: 100%;
  }

  .mf-list {
    margin: 10px 0 30px;
  }

  .cpyright {
    width: 100%;
    text-align: center;
    order: 2;
    padding: 15px 0;
    font-size: 12px;
  }

  .cpyright a {
    padding: 3px;
    display: block;
  }

  .bfooter {
    flex-wrap: wrap;
  }

  .contact a {
    font-size: 24px;
  }

  .main {
    width: 100%;
  }

  h1 {
    font-size: 6.2vw;
    line-height: 6.8vw;
  }

  .kv {
    width: 100%;
    opacity: .15;
    left: 0;
  }

  .kv:before, .kv:after {
    display: none;
  }

  .cta-hbtn {
    font-size: 14px;
  }

  .h-logo {
    width: 80px;
  }

  .cta-h {
    column-gap: 5px;
  }
}

@media (max-width: 600px) {
  h1 {
    font-size: 7.2vw;
    line-height: 7.8vw;
  }
}

@media (max-width: 460px) {
  .maindesc {
    padding: 15px 0 0;
  }

  .cta-hbtn {
    padding: 20px 10px;
  }

  footer {
    padding: 100px 30px 15px;
  }

  .contact {
    width: 100%;
  }

  .address .tftitle {
    display: block;
  }

  .address p {
    text-align: left;
  }

  .address p br {
    display: none;
  }

  .tfooter {
    flex-wrap: wrap;
  }

  .bfooter {
    justify-content: center;
  }

  .sns {
    width: 100%;
    order: 0;
    justify-content: center;
  }

  .lang {
    order: 1;
    padding: 10px 0;
  }

  .lang a {
    border: 1px solid #ff5c5c;
    padding: 10px 20px;
  }
}

@media (max-width: 370px) {
  .main {
    padding: 30px 15px;
  }

  header {
    padding: 0 15px;
  }

  .cta-btn {
    padding: 10px 20px;
    font-size: 14px;
  }

  .cta-h a:last-child {
    display: none;
  }

  h2 {
    font-size: 28px;
    line-height: 32px;
  }

  section {
    padding: 60px 15px;
  }

  .tech-adv {
    padding: 120px 15px 60px;
  }

  footer {
    padding: 100px 15px 15px;
  }

  .mf-list li {
    width: 50%;
    padding: 10px 0;
  }

  .mf-list li:last-child {
    width: 50%;
  }

  .address p {
    width: 180px;
  }
}

/*# sourceMappingURL=index.09a605b2.css.map */
